const paths = [
    {
        slug: 'reactions',
        title: 'Реакции на истории'
    },
    {
        slug: 'recs',
        title: 'Рекомендации'
    },
    {
        slug: 'superboost',
        title: 'Супербууст'
    },
    {
        slug: 'metrics',
        title: 'Метрики'
    },
    {
        slug: 'scheduler',
        title: 'Планировщик'
    },
    {
        slug: 'cloner',
        title: 'Клонер'
    }
]
export default paths.map(path => {
    return {
        path: '/more/'+path.slug,
        component: () => import('@/views/pages/more/'+path.slug+'Page.vue'),
        meta: {requiresAuth: true, title: path.title}
    }
})